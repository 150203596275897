import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Adding Customers" next={{
  name: 'Send Review Requests',
  path: '/send-review-requests'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are three ways to add customers to your Simple RM account. One at a time, with a bulk import, or automatically with a connection to your QuickBooks Online account.`}</p>
    <h2 {...{
      "id": "add-one-customer"
    }}>{`Add one customer`}</h2>
    <p>{`You can add an individual customer to your Simple RM account on the `}<a target="_blank" href='https://app.simplereviewmanagement.com/customers'>{`customers page`}</a>{`. Do the following once you are on the customers page.`}</p>
    <ol>
      <li parentName="ol">{`Click the add customer button to get the create customer pop up to appear`}</li>
      <li parentName="ol">{`Add all the customer information`}</li>
      <li parentName="ol">{`Check the box to send a review request (optional)`}</li>
      <li parentName="ol">{`Click save `}</li>
    </ol>
    <p>{`Customers are required to have a phone number or email address. A first name may also be required depending on how you have your account configured.`}</p>
    <h2 {...{
      "id": "bulk-import-customers"
    }}>{`Bulk import customers`}</h2>
    <p>{`You can import customers from anywhere, including any of your other business accounts, using the bulk import feature.`}</p>
    <p>{`This feature requires the customer information is in a CSV file with the correct structure.`}</p>
    <p>{`Follow along with `}<a parentName="p" {...{
        "href": "/help-center/bulk-customer-import"
      }}>{`this article`}</a>{` to complete a bulk import.`}</p>
    <h2 {...{
      "id": "automatically-with-quickbooks-online"
    }}>{`Automatically with QuickBooks Online`}</h2>
    <p>{`If you connect QuickBooks Online and Simple RM the customer information from QuickBooks online will be automatically available in your Simple RM account.`}</p>
    <p>{`This includes new customers as well. Customers you add to your QuickBooks Online account will be visible in your Simple RM account in a matter of minutes.`}</p>
    <p>{`There are few ways of setting up this connection depending on how far along you are with Simple RM`}</p>
    <p>{`If you do not yet have a Simple RM account follow this `}<a parentName="p" {...{
        "href": "/help-center/account-creation-with-quickbooks-online"
      }}>{`account setup flow`}</a>{`.`}</p>
    <p>{`If you already have a Simple RM account you can connect it to QuickBooks Online by going to the `}<a target="_blank" href='https://app.simplereviewmanagement.com/settings/integrations'>{`integration settings tab`}</a>{`, clicking the connect button and then following this article.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      